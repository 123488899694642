<template>
  <v-app id="inspire" v-if="user">
    <v-navigation-drawer v-model="drawer" color="var(--cl-main)" app width="250" style="box-shadow: 0 0 0 1px #434968">
      <v-sheet color="var(--cl-main)" height="78" width="100%">
        <div class="pa-4" style="color: #b6bee3; cursor: pointer" @click="link(user.isKey ? '/dashboard' : '/input-key')">
          <v-img class="float-start mt-1" width="34" src="../assets/logologo.png"></v-img>
          <h1 class="float-start ml-2 mt-1" style="font-size: 1.375rem">
            PAYLOADCC
          </h1>
        </div>
      </v-sheet>

      <v-list shaped>
        <v-list-item :to="{ path: item.path }" dark v-for="item in menus" :key="item.name" link :disabled="!user.isKey" v-show="item.isActive">
          <v-list-item-content>
            <v-list-item-title style="color: #b6bee3">
              <v-icon size="20">{{ item.icon }}</v-icon>
              <span style="position: absolute; top: 15px" class="ml-3">{{
                item.name
              }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div class="footer">
        <v-list-item dark link @click="dialogUser = true">
          <v-list-item-avatar>
            <v-img :src="user.avatar"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ user.email }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.username }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-dialog v-model="dialogUser" max-width="290">
          <v-card color="#434968" dark>
            <div class="pa-2">
              <v-list-item>
                <v-list-item-avatar>
                  <v-img :src="user.avatar"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ user.email }}</v-list-item-title>
                  <v-list-item-subtitle>{{ user.username }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-text>
                Version 2.0
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="#EF5350" @click="logout()" text>
                  Logout
                </v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>
      </div>
    </v-navigation-drawer>

    <v-main>
      <router-view :user="user" @snackbar="snackbar = $event" />
    </v-main>

    <v-snackbar top v-model="snackbar.snackbar" :timeout="timeout">
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="#b6bee3" text v-bind="attrs" @click="snackbar.snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { authtService } from "@/api/auth.api";
export default {
  methods: {
    async logout() {
      await localStorage.clear();
      window.location.href = "/login";
    },
    async getProfile() {
      let that = this;
      const getProfile = await authtService.GetProfile();
      if (getProfile.status != 200) {
        if (this.$route.path != '/login')
          that.$router.push({
            path: "/login",
          });
      }
      else {
        if (!getProfile.data.isKey) {
          if (that.$route.path != "/input-key") {
            that.$router.push({
              path: "/input-key",
            });
          }
        }
      }

      that.user = getProfile.data;
    },
    link(link) {
      let that = this;
      if (that.$route.path != "/input-key") {
        that.$router.push({
          path: link,
        });
      }
    },
  },
  async created() {
    await this.getProfile();
  },
  data: () => ({
    snackbar: {
      snackbar: false,
      text: "My timeout is set to 2000.",
    },
    dialogUser: false,
    timeout: 2000,
    loading: true,
    drawer: null,
    user: null,
    menus: [
      {
        icon: "fi fi-rr-house-blank",
        name: "Dashboards",
        path: "/dashboard",
        isActive: true
      },
      {
        icon: "fi fi-rr-add-document",
        name: "Add data",
        path: "/create",
        isActive: false
      },
      {
        icon: "fi fi-rr-box-open-full",
        name: "Group",
        path: "/create-group",
        isActive: false
      },
      {
        icon: "fi fi fi-rr-flame",
        name: "3d",
        path: "/3d",
        isActive: true
      },
      // {
      //   icon: "fi fi-rr-ad",
      //   name: "Ads",
      //   path: "/ads",
      // },
      {
        icon: "fi fi-rr-time-past",
        name: "Historys",
        path: "/history",
        isActive: false
      },
      {
        icon: "fi fi-rr-chart-pie",
        name: "Revenue",
        path: "/revenue",
        isActive: false
      },
      {
        icon: "fi fi-rr-money-from-bracket",
        name: "Withdraw",
        path: "/dashboard",
        isActive: true
      },
    ],
  }),
};
</script>

<style>
:root {
  --color-bg-main: #2f3349;
  --color--1: #b6bee3;
  --color-purple: #7367f0;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0 0 0 1px #434968;
}

.bg-label-danger {
  background-color: #4d384b !important;
  color: #ea5455 !important;
}

.card {
  box-shadow: none !important;
  border: 1px solid #434968 !important;
}

.v-main {
  background: var(--cl-main);
  color: #b6bee3;
  font-size: 15px;
}

:root {
  --cl-main: #2f3349;
}

:root {
  --bs-facebook: #3b5998;
  --bs-twitter: #1da1f2;
  --bs-google-plus: #dd4b39;
  --bs-instagram: #e1306c;
  --bs-linkedin: #0077b5;
  --bs-github: #333;
  --bs-dribbble: #ea4c89;
  --bs-pinterest: #cb2027;
  --bs-slack: #4a154b;
  --bs-reddit: #ff4500;
  --bs-youtube: #ff0000;
  --bs-vimeo: #1ab7ea;
}

:root {
  color-scheme: dark;
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

:root {
  font-size: var(--bs-root-font-size);
}

:root,
[data-bs-theme="light"] {
  --bs-blue: #007bff;
  --bs-indigo: #6610f2;
  --bs-purple: #7367f0;
  --bs-pink: #e83e8c;
  --bs-red: #ea5455;
  --bs-orange: #fd7e14;
  --bs-yellow: #ff9f43;
  --bs-green: #28c76f;
  --bs-teal: #20c997;
  --bs-cyan: #00cfe8;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: rgba(0, 0, 0, 0.1);
  --bs-gray-dark: rgba(0, 0, 0, 0.45);
  --bs-gray-25: rgba(255, 255, 255, 0.015);
  --bs-gray-50: rgba(255, 255, 255, 0.03);
  --bs-primary: #7367f0;
  --bs-secondary: #a8aaae;
  --bs-success: #28c76f;
  --bs-info: #00cfe8;
  --bs-warning: #ff9f43;
  --bs-danger: #ea5455;
  --bs-light: #44475b;
  --bs-dark: #d7d8de;
  --bs-gray: rgba(255, 255, 255, 0.8);
  --bs-primary-rgb: 115, 103, 240;
  --bs-secondary-rgb: 168, 170, 174;
  --bs-success-rgb: 40, 199, 111;
  --bs-info-rgb: 0, 207, 232;
  --bs-warning-rgb: 255, 159, 67;
  --bs-danger-rgb: 234, 84, 85;
  --bs-light-rgb: 68, 71, 91;
  --bs-dark-rgb: 215, 216, 222;
  --bs-gray-rgb: 255, 255, 255;
  --bs-primary-text-emphasis: #2e2960;
  --bs-secondary-text-emphasis: #434446;
  --bs-success-text-emphasis: #10502c;
  --bs-info-text-emphasis: #00535d;
  --bs-warning-text-emphasis: #66401b;
  --bs-danger-text-emphasis: #5e2222;
  --bs-light-text-emphasis: rgba(0, 0, 0, 0.3);
  --bs-dark-text-emphasis: rgba(0, 0, 0, 0.3);
  --bs-primary-bg-subtle: #e3e1fc;
  --bs-secondary-bg-subtle: #eeeeef;
  --bs-success-bg-subtle: #d4f4e2;
  --bs-info-bg-subtle: #ccf5fa;
  --bs-warning-bg-subtle: #ffecd9;
  --bs-danger-bg-subtle: #fbdddd;
  --bs-light-bg-subtle: rgba(255, 255, 255, 0.9);
  --bs-dark-bg-subtle: rgba(255, 255, 255, 0.3);
}

:root,
:host {
  --fa-style-family-brands: "Font Awesome 6 Brands";
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
}

:root,
:host {
  --fa-style-family-classic: "Font Awesome 6 Free";
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Free";
}

:root,
:host {
  --fa-style-family-classic: "Font Awesome 6 Free";
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Free";
}

body {}

.bg-main {
  backdrop-filter: saturate(200%) blur(10px);
  background: linear-gradient(180deg,
      rgba(47, 51, 73, 0.7) 44%,
      rgba(47, 51, 73, 0.43) 73%,
      rgba(47, 51, 73, 0));
  -webkit-mask: linear-gradient(#2f3349, #2f3349 18%, transparent 100%);
  mask: linear-gradient(#2f3349, #2f3349 18%, transparent 100%);
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-warring {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}
</style>
