import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import AuthenticationLayout from '@/layout/_auth.vue'
import MainLayout from '@/layout/_main.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '',
    component: AuthenticationLayout,
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/auth/login.vue')
      }
    ]
  },
  {
    path: '/',
    redirect: 'main',
    component: MainLayout,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/main/home.vue')
      },
      {
        path: '/create',
        name: 'create',
        component: () => import('@/views/main/create.vue')
      },
      {
        path: '/create-group',
        name: 'create-group',
        component: () => import('@/views/main/create-group.vue')
      },
      {
        path: '/history',
        name: 'history',
        component: () => import('@/views/main/history.vue')
      },
      {
        path: '/3d',
        name: '3d',
        component: () => import('@/views/main/3d.vue')
      },
      {
        path: '/input-key',
        name: 'input-key',
        component: () => import('@/views/main/input-key.vue')
      },
      {
        path: '/ads',
        name: 'ads',
        component: () => import('@/views/main/ads.vue')
      },
      {
        path: '/revenue',
        name: 'revenue',
        component: () => import('@/views/main/revenue.vue')
      },
    ]
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

console.log(router)

export default router
