<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "App",
  data: () => ({}),
  mounted() {},
  async created() {},
});
</script>

<style lang="scss">

.gap-2 {
  gap: 0.5rem !important;
}

.br-5 {
  border-radius: 5px !important;
}

.d-flex {
  display: flex !important;
}

.bg-label-primary {
  background-color: #3a3b64 !important;
  color: #7367f0 !important;
}

.btn-primary {
  color: #fff;
  background-color: #7367f0;
  border-color: #7367f0;
}

.bg-label-success {
  background-color: #2e4b4f !important;
  color: #28c76f !important;
}

.text-success {
  color: #28c76f !important;
}

.text-warning {
  color: #ff9f43 !important;
}

.o {
  opacity: 0.7;
}

.hv:hover {
  color: #7367f0
}
</style>
