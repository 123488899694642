<template>
  <v-app id="inspire">

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { authtService } from "@/api/auth.api"

export default {
  data: () => ({
    drawer: null
  }),
  async created() {
    await this.getProfile();
  },
  methods: {
    async getProfile() {
      let that = this;
      const getProfile = await authtService.GetProfile();
      if (getProfile.status != 200) {
        if (this.$route.path != '/login')
          that.$router.push({
            path: "/login",
          });
      }
    },
  }
};
</script>

<style>
.bg-label-danger {
  background-color: #4d384b !important;
  color: #ea5455 !important;
}

.card {
  box-shadow: none !important;
  border: 1px solid #434968 !important;
}

.v-main {
  background: var(--cl-main);
  color: #b6bee3;
  font-size: 15px;
}

:root {
  --cl-main: #2f3349;
}

:root {
  --bs-facebook: #3b5998;
  --bs-twitter: #1da1f2;
  --bs-google-plus: #dd4b39;
  --bs-instagram: #e1306c;
  --bs-linkedin: #0077b5;
  --bs-github: #333;
  --bs-dribbble: #ea4c89;
  --bs-pinterest: #cb2027;
  --bs-slack: #4a154b;
  --bs-reddit: #ff4500;
  --bs-youtube: #ff0000;
  --bs-vimeo: #1ab7ea;
}

:root {
  color-scheme: dark;
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

:root {
  font-size: var(--bs-root-font-size);
}

:root,
[data-bs-theme="light"] {
  --bs-blue: #007bff;
  --bs-indigo: #6610f2;
  --bs-purple: #7367f0;
  --bs-pink: #e83e8c;
  --bs-red: #ea5455;
  --bs-orange: #fd7e14;
  --bs-yellow: #ff9f43;
  --bs-green: #28c76f;
  --bs-teal: #20c997;
  --bs-cyan: #00cfe8;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: rgba(0, 0, 0, 0.1);
  --bs-gray-dark: rgba(0, 0, 0, 0.45);
  --bs-gray-25: rgba(255, 255, 255, 0.015);
  --bs-gray-50: rgba(255, 255, 255, 0.03);
  --bs-primary: #7367f0;
  --bs-secondary: #a8aaae;
  --bs-success: #28c76f;
  --bs-info: #00cfe8;
  --bs-warning: #ff9f43;
  --bs-danger: #ea5455;
  --bs-light: #44475b;
  --bs-dark: #d7d8de;
  --bs-gray: rgba(255, 255, 255, 0.8);
  --bs-primary-rgb: 115, 103, 240;
  --bs-secondary-rgb: 168, 170, 174;
  --bs-success-rgb: 40, 199, 111;
  --bs-info-rgb: 0, 207, 232;
  --bs-warning-rgb: 255, 159, 67;
  --bs-danger-rgb: 234, 84, 85;
  --bs-light-rgb: 68, 71, 91;
  --bs-dark-rgb: 215, 216, 222;
  --bs-gray-rgb: 255, 255, 255;
  --bs-primary-text-emphasis: #2e2960;
  --bs-secondary-text-emphasis: #434446;
  --bs-success-text-emphasis: #10502c;
  --bs-info-text-emphasis: #00535d;
  --bs-warning-text-emphasis: #66401b;
  --bs-danger-text-emphasis: #5e2222;
  --bs-light-text-emphasis: rgba(0, 0, 0, 0.3);
  --bs-dark-text-emphasis: rgba(0, 0, 0, 0.3);
  --bs-primary-bg-subtle: #e3e1fc;
  --bs-secondary-bg-subtle: #eeeeef;
  --bs-success-bg-subtle: #d4f4e2;
  --bs-info-bg-subtle: #ccf5fa;
  --bs-warning-bg-subtle: #ffecd9;
  --bs-danger-bg-subtle: #fbdddd;
  --bs-light-bg-subtle: rgba(255, 255, 255, 0.9);
  --bs-dark-bg-subtle: rgba(255, 255, 255, 0.3);
}

:root,
:host {
  --fa-style-family-brands: "Font Awesome 6 Brands";
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
}

:root,
:host {
  --fa-style-family-classic: "Font Awesome 6 Free";
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Free";
}

:root,
:host {
  --fa-style-family-classic: "Font Awesome 6 Free";
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Free";
}

body {}

.bg-main {
  backdrop-filter: saturate(200%) blur(10px);
  background: linear-gradient(180deg,
      rgba(47, 51, 73, 0.7) 44%,
      rgba(47, 51, 73, 0.43) 73%,
      rgba(47, 51, 73, 0));
  -webkit-mask: linear-gradient(#2f3349, #2f3349 18%, transparent 100%);
  mask: linear-gradient(#2f3349, #2f3349 18%, transparent 100%);
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}
</style>
