import axios from "axios";
import _default from "@/config/default";

export interface IAuthService {
    Login: (email: string, password: string) => void
    GetProfile: () => void
}

export class AuthService implements IAuthService {
    public url = "";

    constructor(url: string) {
        this.url = url;
    }

    public async Login(email: string, password: string) {
        try {
            const login = await axios.post(this.url + '/tut/key/login', {
                email, password
            }, {});
            return login.data;
        } catch (error: any) {
            return error.response.data;
        }
    }

    public async GetProfile() {
        try {
            const profile = await axios.get(this.url + '/tut/key/get-profile', {
                headers: {
                    ..._default.token
                }
            });
            return profile.data;
        } catch (error: any) {
            return error.response.data;
        }
    }

    public async Activedkey(key: string) {
        try {
            const profile = await axios.post(this.url + '/tut/key/input', { key }, {
                headers: {
                   ..._default.token
                }
            });
            return profile.data;
        } catch (error: any) {
            return error.response.data;
        }
    }
}

const url = _default.url;
export const authtService = new AuthService(url);